@use '@button' as *;
@use '@fixed-size' as *;
@use '@tabs' as *;
@use '@queries' as *;
@use '@typography' as *;

.filters,
.filters > ul {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  @include min-1440-break {
    gap: 8px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.divider {
  min-height: 32px !important;
  min-width: 1px !important;
  height: 32px !important;
  @include min-1440-break {
    min-height: 46px !important;
    height: 46px !important;
  }
}
